import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../superagent";

const updateShowReferralAlert = async (agentId, data): Promise<void> => {
  try {
    const { body } = await request
      .patch(`${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/${agentId}`)
      .set(await getAuthHeader())
      .send({ data });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { updateShowReferralAlert };

import { Image, Text } from "@clipboard-health/ui-react";
import { REFER_AND_EARN_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/ReferAndEarn/paths";
import { WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/WorkplaceReferrals";
import { useWorkerToWorkplaceAffiliateExperiment } from "@src/appV2/Accounts/WorkplaceReferrals/hooks/useWorkerToWorkplaceAffiliateExperiment";
import {
  WorkplaceReferralAction,
  WorkplaceReferralComponent,
} from "@src/appV2/Accounts/WorkplaceReferrals/types";
import { getWorkerToWorkplaceVariantName } from "@src/appV2/Accounts/WorkplaceReferrals/utils/getWorkerToWorkplaceVariantName";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { LinkCard } from "@src/appV2/lib/Links/LinkCard";

import referralBannerImage from "./images/referral.png";

export function WorkplaceReferralsBanner() {
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();

  return (
    <LinkCard
      to={{
        pathname: isWorkerToWorkplaceAffiliateProgramEnabled
          ? WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH
          : REFER_AND_EARN_WITH_ACCOUNTS_PATH,
        search: `?source=workplaceReferralBanner`,
      }}
      title={
        <Text variant="body2" bold>
          {isWorkerToWorkplaceAffiliateProgramEnabled
            ? "Join the Facility Affiliate Program"
            : "Get paid for facility referrals"}
        </Text>
      }
      subtitle={
        <Text variant="caption">
          Earn $1,000 for every facility you refer to Clipboard, once their first shift is verified!
        </Text>
      }
      variant="info"
      leadingIcon={<Image src={referralBannerImage} alt="" width={48} height={40} />}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
          action: WorkplaceReferralAction.BANNER_CLICKED,
          component: WorkplaceReferralComponent.WORKPLACE_REFERRAL_BANNER,
          variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
        });
      }}
    />
  );
}
